.login-regular {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.hide-modal{
  display: none;
}

.hide-component{
  display: none;
}

.login-box-regular {
  width: 360px;
  background-color: var(--modal-panel-background-color);
  border: var(--modal-border);
  border-radius: var(--modal-border-radius);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: max-content;
  color: black;
  position: relative;
  margin-top: 4vh;
  position: relative;
  padding-bottom: 15px;
}

.login-top-regular {
  min-height: 20px;
  height: 20px;
}

.login-top button {
  user-select: none;
}

.close-login {
  all: unset;
  height: max-content;
  font-size: 2rem;
  cursor: pointer;
  font-family: sans-serif;
  margin-right: 4px;
  padding: 2px 6px 4px 8px;
}

.login-box-regular > .site-logo {
  height: 100px;
  margin: -10px 0 10px 0;
  user-select: none;
  pointer-events: none;
}

.login-form {
  width: 280px;
  display: flex;
  flex-direction: column;
  margin: 0 0 4px 0;
}

.login-form > * {
  display: flex;
  flex-direction: column;
  margin: 6px 0;
}

.login-form label {
  margin-left: 3px;
  margin-bottom: 3px;
  width: max-content;
}

.login-form input {
  padding-left: 4px;
  height: 34px;
  border: 1px solid black;
  border-radius: 3px;
}

.login-form input:focus {
  border-radius: 6px;
  border: none;
}

.login-form .error {
  color: rgb(180, 0, 0);
  font-size: 1.6rem;
  margin-top: 3px;
  font-family: OpenSansCondensed, sans-serf;
}

.login-form .submit-btn {
  height: 40px;
  margin-top: 4px;
  width: 280px;
  border-radius: 20px;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  background-color: var(--navbar-background-color);
}

.login-options{
  display: flex;
  flex-direction: column;
  height: max-content;
  margin: 12px 0 6px 0;
}

.recover-password-link {
  margin-top: 8px;
  text-decoration: none;
  color: blue;
  font-size: 1.6rem;
}

.signup-cont {
  margin: 8px 0;
}

.signup-link {
  text-decoration: none;
  color: blue;
}

/* Mobile Styles */
@media screen and (max-width: 650px) {
}

/* Desktop Styles */
@media screen and (min-width: 651px) and (max-width: 1023px) {
}
