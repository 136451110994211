
.profile-page{
  display: flex;
  flex-direction: column;
  height: 100dvh;
}

.profile {
  display: flex;
  background-color: rgba(250, 250, 250);
  overflow-y: hidden;
  flex: 1;
  max-height: 100%;
  max-width: 100vw;
}

.profile-tabs-container {
  min-width: 190px;
  display: flex;
  border-right: 0.5x solid black;
  justify-content: flex-start;
  flex-direction: column;
  background-color: var(--navbar-background-color);
  filter: brightness(1.65);
  transition: 0.5s width, 0.7s min-width;
  overflow-y: scroll;
  overflow-x: hidden;
}

.profile-tabs {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.profile-tabs li{
  list-style: none;
  background-color: rgb(74, 77, 98);
}

.username-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(0, 0, 0);
  min-height: 60px;
  font-size: 1.6rem;
  font-family: "Roboto_Mono", monospace;
  font-weight: 400;
  width: 100%;
  backdrop-filter: brightness(0.9);
  transition: .4s opacity;
}

.profile-tabs-container li {
  max-height: 90px;
}

.username-container {
  border: 1px solid var(--navbar-background-color);
  background-color: var(--navbar-text-color);
  width: 95%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  transition: 0.9s opacity;
}

.profile-tabs button {
  width: 190px;
  border: 0.5px solid black;
  height: 90px;
  font-size: 2rem;
  cursor: pointer;
  filter: brightness(calc(1 / 1.65));
  color: var(--navbar-text-color);
  transition: 0.5s width, 0.2s background-color, 0.3s margin;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--navbar-highlight-color);
}

.tab-button-label{
  cursor: pointer;

}

.profile-tabs button:hover {
  background-color: rgb(86, 90, 116);
  font-size: 2.1rem;
}

.profile-tabs button > * {
  margin-left: 6px;
}

.profile-tabs img {
  min-width: 56px;
  max-width: 56px;
  filter: invert() brightness(0.95);
}

.profile-tabs .active {
  border: 1.5px solid black;
  border-right: 8px solid rgba(250, 250, 250);
  transition: 0.1s border, 0.5s width, 0.5s filter, 0.3s background-color;
  background-color: var(--navbar-background-color);
}

.profile-tabs .active:hover {
  background-color: var(--navbar-background-color);
  font-size: 2rem;
}

.tab-button-label {
  opacity: 1;
  width: max-content;
  transition: 0.5s width, 0.3s opacity;
}

.profile .logout{
  margin-top: auto;
  margin-bottom: 60px;
}

.contact-tab img{
  padding: 4px;
}

.active-tab{
  width: 100%;
}


/* Mobile Styles */
@media screen and (max-width: 490px) {
  .profile-tabs-container {
    width: 60px;
    min-width: 60px;
    transition: 0.5s width, 0.5s min-Width;
  }

  .profile-tabs button {
    width: 60px;
    transition: 0.5s width;
  }


  .username-container {
    opacity: 0;
    transition: 0.15s opacity;
    width: 0px;
    user-select: none;
  }

  .profile-tabs .active {
    border-top: .5px solid black;
    border-bottom: .5px solid black;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
    transition: 0.5s width, 0.1s border, 0.5s filter;
    filter: brightness(0.8);
  }

  .tab-button-label {
    display: none;
    width: 0px;
    opacity: 0;
    transition: 0.5s width, 0.3s opacity;
  }

  .username-tab{
    opacity: 0;
    transition: .4s opacity;
  }

  .profile-tabs button > * {
    margin: 0;
    transition: 0.3s margin;
  }
}
