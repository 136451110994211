.calendar-icon {
  width: 38px;
  height: 44px;
  display: flex;
  flex-direction: column;
  border: 2px solid white;
  font-family: Inter, Verdana, sans-serif;
  user-select: none;
  background-color: white;
}

.calendar-top {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  height: 10px;
}

.calendar-hole {
  background-color: var(--navbar-background-color);
  width: 6px;
  height: 6px;
  border-radius: 6px;
}

.calendar-month {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  color: var(--navbar-background-color);
  height: 16px;
  font-size: 1.1rem;
}

.calendar-day {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--navbar-background-color);
  color: white;
  height: 32px;
  font-size: 1.6rem;
}
