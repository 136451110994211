.histogram-container {
  display: flex;
  flex-direction: column;
  padding: 10px 0 18px 6px;
}

.histogram-row {
  display: flex;
  column-gap: 6px;
  width: 330px;
  height: 30px;
  margin: 0 4px;
  animation: -2s 4s dropdownHistogram;
}

.average-score {
  margin: 2px 0 6px 10%;
  animation: -2s 3s dropdownAverage;
}

.histogram-score {
  width: 20px;
  max-width: 20px;
  margin-left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.histogram-bar-container {
  width: 260px;
}

.histogram-score {
  animation: -2s 4s dropdownHistogram;
}

.histogram-bar {
  animation: -2s 4s dropdownHistogram;
  height: 30px;
  border-top: solid .5px black;
  border-right: solid 1px black;
  border-bottom: solid .5px black;
  border-left: solid 1px black;
  clip-path: inset(0 1px 0 .5px);
  background-image: url("../../../public/images/game/barBackground.png");
}

.histogram-bar.score-1,
.histogram-bar.score-2 {
  background-color: rgba(0, 255, 0);
  animation: -2s 6s expandBars, 6s 5.5s movingBackground linear infinite;
}

.histogram-bar.score-3,
.histogram-bar.score-4 {
  background-color: rgb(0, 210, 75);
  animation: -2s 6s expandBars, 6s 7s movingBackground linear infinite;
}

.histogram-bar.score-5,
.histogram-bar.score-6 {
  background-color: rgb(255, 255, 0);
  animation: -2s 6s expandBars, 6s 8.5s movingBackground linear infinite;
}

.histogram-bar.score-7,
.histogram-bar.score-8 {
  background-color: rgb(254, 32, 32);
  animation: -2s 6s expandBars, 6s 10s movingBackground linear infinite;
}

.histogram-frequency {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: -2s 4s dropdownFrequency;
}

@keyframes dropdownAverage {
  0% {
    height: 0px;
    opacity: 0;
    margin: 0 0 0 10%;
  }
  85% {
    opacity: 0;
    height: 0px;
    margin: 0 0 0 10%;
  }
  100% {
    height: 23px;
  }
}

@keyframes dropdownHistogram {
  0% {
    height: 0px;
    opacity: 0;
  }
  75% {
    height: 0px;
    opacity: 0;
  }
  100% {
    opacity: 1;
    height: 30px;
  }
}

@keyframes dropdownFrequency {
  0% {
    opacity: 0;
  }
  92.5% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes expandBars {
  0% {
    width: 0px;
  }
  41.66% {
  }
  50%{
  }
  56% {
    width: 0px;
    opacity: 0;
  }
  80%{
    opacity: 1;
  }
  100% {
  }
}

@keyframes movingBackground {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: 840px 0px;
  }
}
