.new-password{
  width: 100%;
  display: flex;
  justify-content: center;
}

.new-password > * {
  margin-top: 15vh;
  width: 360px;
  padding: 6px 0 20px 0;
  background-color: var(--modal-panel-background-color);
  border: var(--modal-border);
  border-radius: var(--modal-border-radius);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: max-content;
  color: black;
}

.reset-password-form {
  width: 280px;
  display: flex;
  flex-direction: column;
  margin: 0 80px;
  margin-top: 10px;
}

.reset-password-form > * {
  display: flex;
  flex-direction: column;
  margin: 6px 0;
}

.reset-password-form label {
  margin-left: 3px;
  margin-bottom: 3px;
}

.reset-password-form input {
  padding-left: 4px;
  height: 34px;
  border: 1px solid black;
  border-radius: 3px;
}

.reset-password-form input:focus {
  border-radius: 6px;
  border: none;
}

.reset-password-form .error {
  color: rgb(180, 0, 0);
  font-size: 1.6rem;
  margin-top: 3px;
  margin-left: 6px;
  margin-bottom: -16px;
  font-family: OpenSansCondensed, sans-serf;
}

.recover-password-link {
  margin-top: 8px;
  text-decoration: none;
  color: blue;
  font-size: 1.6rem;
}

.signup-cont {
  margin: 8px 0;
}

.signup-link {
  text-decoration: none;
  color: blue;
}

.new-password .submit-btn {
  height: 40px;
  margin-top: 12px;
  width: 280px;
  border-radius: 20px;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  background-color: var(--navbar-background-color);
}

.reset-password-form-container .signup-label {
  font-family: sans-serif;
  font-size: 2.4rem;
  font-weight: bold;
  padding: 0 10% 12px 10%;
  width: 100%;
  margin-top: -12px;
  margin-bottom: -2px;
}

.reset-success-message,
.reset-failure-message {
  font-size: 2rem;
  width: 95%;
  margin: 20px 0;
  text-align: center;
}

.hide{
  display: none;
}

