

.settings-modal{
    z-index: 3;
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding-top: 10vh;
    background-color: rgba(0,0,0,.2);
}


.new-username-container, .new-password-container, .delete-account-container, .settings-modal .success-container{
    width: 360px;
    background-color: white;
    border: 1px solid black;
    height: max-content;
    padding: 24px 0 24px;
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    border-radius: 6px;
}

.settings-modal .success-container{
    align-items: center;
}

.settings-modal .modal-top{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.settings-modal .modal-top button{
    all: unset;
    width: 32px; 
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: -20px -3px -12px 0;
    padding: 4px 0 0 0;
    font-size: 2.0rem;
}

.new-username-container > *, .new-password-container > *, .delete-account-container > *,  .settings-modal .success-container > *{
    padding: 0 10px;
}

.settings-modal h1{
    all: unset;
    color: black;
    width: max-content;
    padding: 0px 4px 1px 4px;
    border-bottom: .5px solid black;
    font-size: 2.4rem;
    margin-bottom: 4px;
}

.username-info{
    font-size: 1.6rem;
    text-indent: 32px;
    margin-left: 8px;
}

.delete-account-info {
    font-size: 1.6rem;
    padding: 8px 12px 8px 18px;
}

.settings-modal form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.settings-modal .form-input{
    display: flex;
    flex-direction: column;
    width: 90%;
    row-gap: 2px;
    padding: 0 10px;
    margin: 2px 0 6px 0;
}

.settings-modal label{
    margin-left: 3px;
    font-size: 2.0rem;
}

.settings-modal input{
    margin: 0 auto;
    height: 34px;
    width: 100%;
    padding-left: 4px;
}

.settings-modal .error{
    color: rgb(180, 0, 0);
    font-size: 1.6rem;
    padding-top: 3px;
    padding-left: 6px;
    font-family: OpenSansCondensed, sans-serf;
}

.confirmation-btn {
    height: 40px;
    margin: 4px 0;
    width: 280px;
    border-radius: 20px;
    border: none;
    color: white;
    font-size: 2.2rem;
    cursor: pointer;
    background-color: var(--navbar-background-color);
  }

.settings-modal .submit-btn {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 3px 2px;
    margin-top: 12px;
    width: 280px;
    border-radius: 20px;
    border: none;
    color: white;
    font-size: 2rem;
    cursor: pointer;
    background-color: var(--navbar-background-color);
}

.settings-modal .submit-btn:hover{
    filter: brightness(.8);
}

.hide{
    display: none;
}
