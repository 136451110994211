

.site-message{
    width: 100vw;
    display: flex;
    justify-content: center;
    margin-top: 15vh;
}

.message-container{
    width: 360px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    padding: 30px 10px 20px;
}

.site-message .message{
    padding: 0 5%;
    text-align: center;
    text-wrap: pretty;
}

.confirmation-btn a{
    color: rgb(250, 250, 250);
    text-decoration: none;
}