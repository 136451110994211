.password-container input {
  padding-left: 4px;
  height: 34px;
  border: 1px solid black;
  border-radius: 3px;
  width: 100%;
  padding-right: 36px;
}

.password-container {
  position: relative;
  display: flex;
  align-items: center;
}

.password-container .toggle-password {
  position: absolute;
  height: 34px;
  right: 0px;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  border-left: 0.5px solid black;
}

.password-container .toggle-password img {
  height: 20px;
  margin: auto 6px;
  user-select: none;
}
