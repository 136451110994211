.account-settings,
.statistics,
.contact {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}

/*Stats tab */
.statistics-tabs {
  display: flex;
  width: 100%;
  border-bottom: 2px solid black;
}

button.random-stats,
button.daily-stats {
  all: unset;
  height: 56px;
  width: 150px;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.9rem;
  background-color: var(--leaderboardTabColor);
  border: 1px solid var(--leaderboardTabColorBorder);
  color: white;
  cursor: pointer;
  user-select: none;
  font-weight: bolder;
  transition: all 0.2s;
}

button.random-stats.clicked,
button.daily-stats.clicked {
  filter: brightness(1.3);
  transition: all 0.2s;
}

.statistics-tabs button:hover:not(.clicked) {
  filter: brightness(1.15);
  font-size: 2rem;
  transition: all 0.1s;
}

.stats-tab-empty {
  flex-grow: 1;
  border-top: 1px solid black;
}

.stats-game-modes-container {
  display: flex;
  flex-wrap: wrap;
  padding-left: 40px;
  padding-bottom: 180px;
  column-gap: 8px;
  row-gap: 8px;
}

.statistics h1 {
  margin: 6px 0 10px 4px;
  color: black;
}

.stats-game-mode {
  height: max-content;
  border: 3px solid rgb(8, 8, 62);
  border-radius: 14px;
  margin: 2px 2px;
  max-width: max-content;
  width: max-content;
}

.stats-game-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 54px;
  font-size: 2.4rem;
  border-bottom: 1px solid rgb(5, 5, 52);
  background-color: rgb(72, 74, 106);
  color: white;
  font-weight: bolder;
  padding-top: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 8px 24px 2px 16px;
}

.stats-game-name > * {
  display: flex;
  justify-content: center;
}

.stats-game-name img {
  height: 28px;
}

.stats-game-name .daily-icon {
  margin-bottom: 5px;
}

.averages-container {
  background-color: rgb(240, 241, 249);
  display: flex;
}

.averages-container.random {
  min-height: 264px;
  min-height: max-content;
}

.averages-container.daily {
  min-height: 200px;
  min-height: max-content;
}

.averages-container > * {
  border-right: 2px solid rgb(8, 8, 62);
}

.averages-container > *:last-child {
  border-right: none;
}

.averages {
  padding: 0 0 16px 0;
}

.averages > * {
  display: flex;
  justify-content: space-between;
  width: 292px;
  border-bottom: 1px solid black;
  background-color: rgb(250, 250, 250);
}

.averages > *:first-child > * {
  min-height: 32px;
}

.averages > *:last-child {
  border-bottom: 1px solid black;
}

.stat-name {
  width: 180px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.stat-value {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  padding: 1.5px 0 1.5px 0;
  background-color: rgb(212, 231, 224);
  border-left: 1px solid black;
  height: 100%;
}

.daily-histogram,
.random-histogram {
  min-height: 130px;
}

.show-histogram-container {
  width: 100%;
  min-height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.show-histogram-container.random,
.show-histogram-container.daily {
  display: none;
}

.show-histogram-container button {
  all: unset;
  border: 0.5px solid black;
  width: max-content;
  padding: 0px 10px;
  font-size: 1.7rem;
  cursor: pointer;
  height: 27px;
  margin-right: 10px;
  border-radius: 20px;
  background-color: rgb(240, 240, 240);
}

.show-histogram-container button:hover {
  filter: brightness(0.9);
}

.reset-stats-container {
  border-top: 2px solid black;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 38px;
  background-color: rgb(165, 111, 111);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0 8px;
}

button.reset-stats {
  all: unset;
  padding: 4px 12px;
  background-color: rgb(240, 240, 240);
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid black;
  transition: filter 0.2s;
}

button.reset-stats:hover {
  filter: brightness(0.8);
  transition: filter 0.2s;
}

@media screen and (max-width: 992px) {
  .averages-container.random {
    flex-direction: column;
  }

  .averages-container.random > * {
    border-right: none;
  }

  .averages-container.random > * > *:first-child {
    border-top: 0.5px solid black;
  }

  .stats-game-mode.random .stats-icon {
    display: none;
  }

  .show-histogram-container.random {
    display: flex;
  }

  .random-histogram {
    display: none;
  }

  .averages-container.random {
    min-height: 292px;
  }

  .stats-game-modes-container {
    width: 100%;
  }
}

@media screen and (max-width: 864px) {
  .averages-container.daily {
    flex-direction: column;
  }

  .averages-container.daily > * {
    border-right: none;
  }

  .averages-container.daily > * > *:first-child {
    border-top: 0.5px solid black;
  }

  .stats-game-mode.daily .stats-icon {
    display: none;
  }

  .show-histogram-container.daily {
    display: flex;
  }

  .daily-histogram {
    display: none;
  }

  .averages-container.daily {
    min-height: 224px;
  }

  .stats-game-modes-container {
    width: 100%;
  }

  .daily-icon {
    display: none;
  }
}

@media screen and (min-width: 490px) and (max-width: 560px) {
  .stats-game-modes-container {
    padding-left: 0px;
    justify-content: center;
    width: 100%;
  }
}

@media screen and (max-width: 490px) {
  .stats-game-modes-container {
    padding-left: 0px;
    justify-content: center;
    width: 100%;
  }
}

/* Stats modal */
.reset-modal,
.histogram-modal {
  z-index: 3;
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding-top: 10vh;
  background-color: rgba(0, 0, 0, 0.2);
}

.histogram-modal {
  cursor: pointer;
}

.histogram-modal .histogram-modal-container {
  cursor: auto;
}

.reset-modal-container,
.histogram-modal-container {
  width: 360px;
  background-color: white;
  border: 1px solid black;
  height: max-content;
  padding: 24px 0 24px;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  border-radius: 6px;
  z-index: 3;
}

.privacy-modal, .refund-modal{
  z-index: 3;
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  overflow-y: scroll;
  padding: 5vh 0 10px;
}

.privacy-modal-container, .refund-modal-container{
  z-index: 6;
  width: 90vw;
  background-color: white;
  border: 1px solid black;
  height: max-content;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  border-radius: 6px;
}

.privacy-modal-container .modal-top .close-modal, .refund-modal-container .modal-top .close-modal{
  padding: 2px 4px;
  margin: -16px 10px 0 0;
}

.privacy-modal .modal-top, .refund-modal .modal-top{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: -30px;
}

.privacy-modal .modal-top button, .refund-modal .modal-top button{
  all: unset;
  width: 32px; 
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: -20px -3px -12px 0;
  padding: 4px 0 0 0;
  font-size: 2.0rem;
}

.reset-modal .modal-top,
.histogram-modal .modal-top {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.histogram-modal .histogram-row {
  user-select: none;
}

.reset-modal .modal-top button,
.histogram-modal .modal-top button {
  all: unset;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: -20px -3px -12px 0;
  padding: 4px 0 0 0;
  font-size: 2rem;
}

.reset-modal .reset-modal-container > *,
.histogram-modal .histogram-modal-container > * {
  padding: 0 10px;
}

.reset-modal h1,
.histogram-modal h1 {
  all: unset;
  color: black;
  width: max-content;
  padding: 0px 12px 1px 12px;
  border-bottom: 0.5px solid black;
  font-size: 2.4rem;
  margin-bottom: 4px;
}

.reset-modal .reset-text {
  display: flex;
  font-size: 1.9rem;
  padding: 10px 40px 0 40px;
  text-align: center;
}

.reset-confirmation-btns {
  display: flex;
  column-gap: 8px;
  margin: 12px 4px 0 4px;
}

.reset-btn:hover, .cancel-btn:hover {
  filter: brightness(.8);
}

.reset-confirmation-btns > button {
  border: 0.8px solid black;
}

.reset-modal .reset-btn.confirmation-btn {
  background-color: var(--higherColor);
}

/*Profile Settings Tab */

.account-settings h1 {
  padding-left: 10px;
  color: black;
}

.account-settings main {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding-left: 20px;
  padding-bottom: 40px;
}

.settings-option-container {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  width: max-content;
  width: 100%;
}

.settings-option {
  font-size: 2.2rem;
  font-weight: bolder;
}

.settings-option > * {
  width: max-content;
  border-bottom: 1px solid var(--dark-blue-text);
  padding: 2px 6px;
}

.change-settings {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  font-size: 1.8rem;
  padding: 6px 20px;
  text-wrap: balance;
}

.change-settings button {
  all: unset;
  width: 180px;
  border: 0.5px solid black;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 4px;
  font-size: 1.7rem;
  cursor: pointer;
  background-color: rgb(240, 240, 240);
  transition: filter 0.1s;
}

.change-settings button:hover {
  filter: brightness(0.9);
  transition: filter 0.2s;
}

.change-settings .username {
  font-family: "Roboto_Mono", monospace;
  font-weight: 400;
}

.inline {
  display: inline-block;
}

.change-settings.no-random a {
  margin: 3px 0;
  color: var(--navbar-color);
}

.account-settings .random-modal-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  padding: 4px 0px;
  width: 240px;
  border-radius: 20px;
  border: none;
  color: white;
  font-size: 1.8rem;
  cursor: pointer;
}

.account-settings a {
  text-decoration: none;
}

.privacy-policy-btn, .refund-policy-btn{
  all: unset;
  padding-left: 8px;
  color: rgb(75, 75, 255);
  cursor: pointer;
  width: max-content;
  padding: 1px 6px;
  margin: 1px 2px;
}

@media screen and (max-width: 490px) {
  .settings-option-container {
    width: 100%;
  }
}

/** Contact tab */
.contact {
  padding-left: 10px;
}

.contact > * {
  width: 100%;
}

.contact h2.contact-text {
  display: flex;
  column-gap: 6px;
}

.contact .contact-text {
  padding: 5px 0 5px 15px;
  display: flex;
  flex-wrap: wrap;
}

.contact-text.email > * {
  margin: 2px 0;
}

@media screen and (max-width: 559px) {
  .contact h2.contact-text {
    flex-direction: column;
  }
}

@media screen and (max-width: 490px) {
  .contact {
    padding-left: 4px;
  }

  .contact > * {
    width: 100%;
  }

  .contact .contact-text {
    padding: 5px 0 5px 6px;
  }
}
