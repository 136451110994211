.histogram-container.profile {
  display: flex;
  flex-direction: column;
  padding: 3px 0 6px 6px;
  background-color: white;
}

.histogram-container.profile .histogram-row {
  display: flex;
  column-gap: 6px;
  width: max-content;
  height: 16px;
  margin: 0 4px;
  animation: -2s 4s dropdownHistogram;
  animation: none;
  font-size: 1.6rem;
}

.histogram-container.profile .average-score {
  margin: 2px 0 6px 10%;
  animation: -2s 3s dropdownAverage;
  animation: none;
}

.histogram-container.profile .histogram-score {
  width: 20px;
  max-width: 20px;
  margin-left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.histogram-container.profile .histogram-bar-container {
  width: 260px;
}

.histogram-container.profile .histogram-bar {
  animation: -2s 4s dropdownHistogram;
  height: 16px;
  border-top: solid 0.5px black;
  border-right: solid 1px black;
  border-bottom: solid 0.5px black;
  border-left: solid 1px black;
  clip-path: inset(0 1px 0 0.5px);
  background-image: url("../../../public/images/game/barBackground.png");
}

/* These are for the stats modal */
.histogram-modal-container .histogram-container.profile {
}

.histogram-modal-container .histogram-container.profile .histogram-row {
  display: flex;
  column-gap: 6px;
  width: 330px;
  height: 30px;
  margin: 0 4px;
}

.histogram-modal-container .histogram-container.profile .histogram-bar {
  animation: -2s 4s dropdownHistogram;
  height: 30px;
  border-top: solid 0.5px black;
  border-right: solid 1px black;
  border-bottom: solid 0.5px black;
  border-left: solid 1px black;
  clip-path: inset(0 1px 0 0.5px);
  background-image: url("../../../public/images/game/barBackground.png");
}

.histogram-container.profile .histogram-score {
  animation: none;
}

.histogram-container.profile .histogram-bar.score-1,
.histogram-container.profile .histogram-bar.score-2 {
  background-color: rgb(0, 255, 0);
  animation: -2s 3.2s expandBars, 6s 4s movingBackground linear infinite;
}

.histogram-container.profile .histogram-bar.score-3,
.histogram-container.profile .histogram-bar.score-4 {
  background-color: rgb(0, 210, 75);
  animation: -2s 3.2s expandBars, 6s 6.5s movingBackground linear infinite;
}

.histogram-container.profile .histogram-bar.score-5,
.histogram-container.profile .histogram-bar.score-6 {
  background-color: rgb(255, 255, 0);
  animation: -2s 3.2s expandBars, 6s 8s movingBackground linear infinite;
}

.histogram-container.profile .histogram-bar.score-7,
.histogram-container.profile .histogram-bar.score-8 {
  background-color: rgb(254, 32, 32);
  animation: -2s 3.2s expandBars, 6s 9.5s movingBackground linear infinite;
}

.histogram-container.profile .histogram-frequency {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: -2s 1.5s dropdownFrequency;
}

@keyframes dropdownAverage {
  0% {
    height: 0px;
    opacity: 0;
    margin: 0 0 0 10%;
  }
  85% {
    opacity: 0;
    height: 0px;
    margin: 0 0 0 10%;
  }
  100% {
    height: 23px;
  }
}

@keyframes dropdownHistogram {
  0% {
    height: 0px;
    opacity: 0;
  }
  75% {
    height: 0px;
    opacity: 0;
  }
  100% {
    opacity: 1;
    height: 30px;
  }
}

@keyframes dropdownFrequency {
  0% {
    opacity: 0;
  }
  92.5% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes expandBars {
  0% {
    width: 0px;
  }
  41.66% {
  }
  50% {
  }
  56% {
    width: 0px;
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
  }
}

@keyframes movingBackground {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: 840px 0px;
  }
}
