.forgot-regular {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.hide-modal {
  display: none;
}

.hide-component {
  display: none;
}

.forgot-box-regular {
  width: 360px;
  padding: 6px 0 20px 0;
  background-color: var(--modal-panel-background-color);
  border: var(--modal-border);
  border-radius: var(--modal-border-radius);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: max-content;
  color: black;
  position: relative;
  margin-top: 10vh;
}

.forgot-top-regular {
  min-height: 31px;
  height: 31px;
}

.forgot-top button {
  user-select: none;
}

.close-login {
  all: unset;
  height: max-content;
  font-size: 2rem;
  cursor: pointer;
  font-family: sans-serif;
  margin-right: 4px;
  padding: 2px 6px 4px 8px;
}

.forgot-box-regular > .site-logo {
  height: 100px;
  margin: -10px 0 10px 0;
  user-select: none;
  pointer-events: none;
}

.forgot-box-regular > .reset-label {
  font-family: sans-serif;
  font-size: 2.4rem;
  font-weight: bold;
  padding: 0 10% 12px 10%;
  margin-top: -12px;
  width: 100%;
}

.forgot-form {
  width: 280px;
  display: flex;
  flex-direction: column;
  margin: 0 0 20px 0;
}

.forgot-form > * {
  display: flex;
  flex-direction: column;
  margin: 6px 0;
}

.forgot-form label {
  margin-left: 3px;
  margin-bottom: 3px;
  width: max-content;
}

.forgot-form input {
  padding-left: 4px;
  height: 34px;
  border: 1px solid black;
  border-radius: 3px;
}

.forgot-form input:focus {
  border-radius: 6px;
  border: none;
}

.forgot-form .error {
  color: rgb(180, 0, 0);
  font-size: 1.6rem;
  margin-top: 3px;
  margin-left: 6px;
  margin-bottom: -12px;
  font-family: OpenSansCondensed, sans-serf;
}

.forgot-box .submit-btn {
  height: 40px;
  margin-top: 12px;
  width: 280px;
  border-radius: 20px;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  background-color: var(--navbar-background-color);
}

.signup-cont {
  margin: 8px 0;
}

.signup-link {
  text-decoration: none;
  color: blue;
}

.forgot-success-message {
  font-size: 2rem;
  width: 90%;
  margin: 20px 0;
  text-align: center;
}

.forgot-form .submit-btn {
  height: 40px;
  margin-top: 12px;
  width: 280px;
  border-radius: 20px;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  background-color: var(--navbar-background-color);
}

.loading-reset-password {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-reset-password > * {
  margin-top: 10vh;
}

/* Mobile Styles */
@media screen and (max-width: 650px) {
}

/* Desktop Styles */
@media screen and (min-width: 651px) and (max-width: 1023px) {
}
